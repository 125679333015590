.figure {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: min-content;
  transition: 0.2s ease;
  margin: 0;
}

.link svg {
  width: auto;
  height: calc(var(--scale-sm) / 2 - 0.5rem);
}
.link path {
  stroke: lightgray;
}

.brand svg {
  width: calc(var(--scale-sm) / 2);
  height: calc(var(--scale-sm) / 2);
}

.figure svg {
  fill: lightgray;
}
.figure path {
  height: var(--scale-sm);
  width: var(--scale-sm);
}
.inactive path {
  transition: 0.2s ease;
}
.active path {
  stroke: var(--two);
  transition: 0.2s ease;
}

.caption {
  margin-top: 8px;
  color: lightgray;
}
.inactive figcaption {
  transition: 0.2s ease;
}
.active figcaption {
  color: var(--two);
  transition: 0.2s ease;
}
