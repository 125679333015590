.definition {
  position: relative;
  width: 100%;
  max-height: 120rem;
  margin-bottom: 1rem;
  padding: calc(var(--scale) / 2) var(--scale) calc(var(--scale) * 1 + 3rem)
    var(--scale);
  border: 1px solid var(--background-accent);
  border-radius: calc(var(--scale) / 4);
  overflow: hidden;

  transition: var(--animation-sloth) ease-in-out;
}

.definition.expanded {
  max-height: 10rem;
  transition: var(--animation-sloth) ease-in-out;
}

.def {
  margin-top: 1rem;
  float: left;
}
.function {
  float: right;

  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: xx-large;
  font-weight: bolder;
  font-style: italic;
}

.dropdown {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%) rotate(0deg);
  transition: var(--animation-med) ease-in-out;
}

.dropdown.expanded {
  transform: translateX(-50%) rotate(180deg);
  transition: var(--animation-med) ease-in-out;
}

.signVideo {
  width: min-content;
  margin: calc(var(--scale) * 1.25 + 3rem) auto 0 auto;
}

.info {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  transition: var(--animation-med) ease-in-out;
  opacity: 0;
}
.info path {
  fill: #ffffff;
  stroke: #ffffff;
  opacity: 25%;
}
.info.expanded {
  opacity: 1;
  transition: var(--animation-med) ease-in-out;
}
