/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: normal;
  src: url("Montserrat-Thin.woff2") format("woff2"),
    url("Montserrat-Thin.woff") format("woff");
}

/** Montserrat ExtraLight **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: normal;
  src: url("Montserrat-ExtraLight.woff2") format("woff2"),
    url("Montserrat-ExtraLight.woff") format("woff");
}

/** Montserrat Light **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: url("Montserrat-Light.woff2") format("woff2"),
    url("Montserrat-Light.woff") format("woff");
}

/** Montserrat Regular **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("Montserrat-Regular.woff2") format("woff2"),
    url("Montserrat-Regular.woff") format("woff");
}

/** Montserrat Regular-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
  src: url("Montserrat-Italic.woff2") format("woff2"),
    url("Montserrat-Italic.woff") format("woff");
}

/** Montserrat Medium **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url("Montserrat-Medium.woff2") format("woff2"),
    url("Montserrat-Medium.woff") format("woff");
}

/** Montserrat SemiBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  src: url("Montserrat-SemiBold.woff2") format("woff2"),
    url("Montserrat-SemiBold.woff") format("woff");
}
