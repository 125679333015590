.link {
  display: flex;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  place-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  transition: 0.2s ease-in-out;
}

.link:hover,
.link:focus {
  filter: invert(100%);
  transition: 0.2s ease-in-out;
}
