:root {
  --background-color: #222222;
  --background-accent: #373a3c;

  --text-color: rgba(255, 255, 255, 0.6);
  --text-color-light: rgba(255, 255, 255, 0.75);

  --scale: 1.5rem;

  --animation-sloth: 0.8s;
  --animation-slow: 0.5s;
  --animation-med: 0.3s;
  --animation-fast: 0.2s;
}

:root {
  --one: #f5a54c;
  --two: #d66c4a;
  --three: #eb505f;
  --four: #d36bd6;
  --five: #af73fa;
}

@import url('./resources/fonts/Montserrat/Montserrat.css');

html {
  height: 100%;
}
body {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 576px) {
  :root {
    --scale: 1.5rem;
    --scale-sm: 2.5rem;
  }
}
@media (min-width: 576px) {
  :root {
    --scale: 1.5rem;
    --scale-sm: 2.5rem;
  }
}
@media (min-width: 768px) {
  :root {
    --scale: 2.5rem;
    --scale-sm: 2.5rem;
  }
}
@media (min-width: 992px) {
  :root {
    --scale: 3.25rem;
    --scale-sm: 3.25rem;
  }
}
@media (min-width: 1200px) {
  :root {
    --scale: 4rem;
    --scale-sm: 4rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h7,
h6,
label {
  color: var(--text-color-light);
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: var(--text-color);
  margin-top: 0;
  margin-bottom: 1rem;
}
a,
button,
input,
label {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

li {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: var(--text-color);
  margin-top: 0;
  margin-bottom: 1rem;
}

::-webkit-input-placeholder {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
::-moz-placeholder {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
:-ms-input-placeholder {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
:-moz-placeholder {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.h7,
h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.h1 > *,
h1 > * {
  margin-left: calc(2.5rem / 4);
  font-size: 2.5rem;
}
.h2 > *,
h2 > * {
  margin-left: calc(2rem / 4);
  font-size: 2rem;
}
.h3 > *,
h3 > * {
  margin-left: calc(1.75rem / 4);
  font-size: 1.75rem;
}
.h4 > *,
h4 > * {
  margin-left: calc(1.5rem / 4);
  font-size: 1.5rem;
}
.h5 > *,
h5 > * {
  margin-left: calc(1.25rem / 4);
  font-size: 1.25rem;
}
.h6 > *,
h6 > * {
  margin-left: calc(1rem / 4);
  font-size: 1rem;
}

.h7,
h7 {
  margin-left: calc(1rem / 4);
  font-size: 1rem;
}
.h7 > *,
h7 > * {
  margin-left: calc(0.75rem / 4);
  font-size: 0.75rem;
}

html,
body,
.App {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  min-height: 100vh;
}

.App-body {
  min-height: 90vh;
  padding: 40px 0 120px 0;
  background-color: var(--background-color);
}

@media (min-width: 1200px) {
  .navbar-nav {
    align-items: center;
  }
  .App-body {
    padding: 40px 0;
  }
}

.dropdown-button,
.dropdown-button:hover,
.dropdown-button:active,
.dropdown-button:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
